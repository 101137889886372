@import 'node_modules/@blum-rivendell/reactui/components/assets/fonts/Yantramanav/font.css';
@tailwind components;
@tailwind utilities;

html,
body {
  @apply bl-text-neutral-dark-50;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
  font-family: 'Yantramanav', system-ui, -apple-system, BlinkMacSystemFont,
    sans-serif !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  cursor: pointer !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f0f5fa inset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
textarea:focus,
input:focus {
  outline: none;
}
